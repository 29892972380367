<script lang="ts" setup>
import { useLeaderboardStore } from '#leaderboard/store/useLeaderboardStore'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'

const route = useRoute()

useHead({
  title: 'Leaderboards Menu',
})

const navItems = [
  { label: 'By Depth, Volume, & Spread', link: '/leaderboards', icon: 'i-heroicons-square-3-stack-3d' },
  { label: 'By Depth Only', link: '/leaderboards/depth', icon: 'i-heroicons-outline-book-open' },
  {
    label: 'By Volume Only',
    link: '/leaderboards/volume',
    icon: 'i-heroicons-outline-presentation-chart-line',
  },
  { label: 'By Spread Only', link: '/leaderboards/spread', icon: 'i-heroicons-outline-document-arrow-up' },
]

/**
 * This checks whether the 'Apply to all leaderboards'
 * option of the Leaderboard Global Filter toggle is on.
 * If on, the page filters are saved for all leaderboards pages.
 * If it is off, the page filters are reset on new page.
 */
const store = useLeaderboardStore()
const { applyFiltersGlobally } = storeToRefs(store)
const { resetFilters } = store

watch(
  () => route.path,
  (oldPath, newPath) => {
    const resetPages = ['/leaderboards', '/leaderboards/depth', '/leaderboards/volume', '/leaderboards/spread']

    if (oldPath !== newPath && resetPages.includes(newPath) && !applyFiltersGlobally.value) {
      resetFilters()
    }
  },
)
</script>

<template>
  <NuxtLayout name="dashboard">
    <template #sidebar>
      <div
        class="flex flex-col justify-start h-full border-r border-forgd-bgd-600 w-[14.25rem] px-2 py-4"
      >
        <nav aria-label="Leaderboard Navigation" class="flex flex-col flex-shrink-0 sticky top-0 gap-1">
          <span class="w-full text-xs font-normal text-neutral-700 mb-1">Rank Market Makers by:</span>
          <UButton
            v-for="navItem in navItems"
            :key="navItem.link"
            :to="navItem.link"
            color="gray"
            variant="ghost"
            active-class="bg-neutral-400"
            class="w-full h-8.5 rounded hover:bg-neutral-400 p-2"
          >
            <UIcon :key="navItem.icon" :name="navItem.icon" class="w-3.5 h-3.5" />
            <span class="text-xs whitespace-nowrap text-gray-600 font-normal">
              {{ navItem.label }}
            </span>
          </UButton>
        </nav>
      </div>
    </template>

    <slot />
  </NuxtLayout>
</template>
